import { BlockContainer } from '@components/Blocks';
import CtaButton from '@components/CtaButton';
import Grid from '@componentPrimitives/Grid';
import Image from '@components/Image';
import Txt from '@primitive/Txt';
import { createBlock } from '@lib/features/blocks/blocks';

const ImageAndText = createBlock<'blocks_imageAndText_BlockType'>(
  ({ heading, content, linkCta, flipAlignment, image }) => {
    return (
      <BlockContainer>
        <Grid
          cx={{
            alignItems: 'center',
            justifyContent: 'between',
            flexDirection: { md: flipAlignment ? 'row-reverse' : 'row' },
          }}>
          <Grid.Col md={6} lg={5}>
            <Txt variant="h5" as="h2" cx={{ mB: 'gutter' }}>
              {heading}
            </Txt>
            <Txt html>{content}</Txt>
            {linkCta && (
              <CtaButton variant="outlined" size="small" {...linkCta} cx={{ mT: 'md' }} />
            )}
          </Grid.Col>
          <Grid.Col md={6}>
            <Image ratio="landscapeTall" rounded alt="" {...image} />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ImageAndText;
